// Dependency Imports
import React from "react";
import {Button, Alert} from "react-bootstrap";
import {auth, signInWithGoogle} from "../firebase";

// Google icons for login buttons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGoogle} from "@fortawesome/free-brands-svg-icons/faGoogle";

// Style Imports
import '../styles/HomeNoAuth.css'
import phone from "../images/phone.png";
import {useAuthState} from "react-firebase-hooks/auth";

const HomeNoAuth = () => {

    // noinspection JSCheckFunctionSignatures
    const [user] = useAuthState(auth);

    return (
        <div id={'wrap'} >
            <div id={'left'}>
                <img style={{textAlign: "center"}} className={'phone'} src={phone} alt={'phone'} />
            </div>
            <div id={'right'} style={{paddingTop: "90px", paddingRight: "40px"}}>
                <h1>The Digital Greenhouse 🌲</h1>
                <hr />
                <p className={'heading-para'}>An all-in-one digital solution for managing your plants.</p>
                <br />
                <p style={{fontSize: "large"}}>The digital greenhouse is a way to keep track of the plants you take care of at home, it enables you to keep track of information and watering times so that you never forget to take care of a plant again.</p>
                <br />
                <h3>🗃 Key Features</h3>
                <hr />
                <ul style={{textAlign: "left"}}>
                    <li>🌍 Web based interface with responsive design</li>
                    <li>🖥 / 📱 Cross Platform Compatibility ➡ Desktop & Mobile</li>
                    <li>🧑 Multi-User Capability using Google Authentication</li>
                    <li>📗 Gallery based interface to visualize all of your stored plants</li>
                    <li>🌊 Never forget to water your plants with reminders and progress indicators for watering times</li>
                    <li>🖼 Image upload system to easily identify and keep track of your plants</li>
                </ul>
                <br />
                <Alert show={true} variant={'light'}>
                    {user ?
                        <p>Welcome <b>{user.displayName}</b>, you are logged into the site. You can now navigate to your greenhouse.</p>
                        :
                        <p>Sorry you are not logged in right now, click here to sign up with Google.</p>
                    }
                    {user ?
                        <Button variant="info" onClick={(e) => window.location.href='/greenhouse'}>
                            🌲 Go to Greenhouse
                        </Button>
                        :
                        <Button variant="outline-success" onClick={signInWithGoogle}>
                            <FontAwesomeIcon icon={faGoogle}/> Sign Up with Google
                        </Button>

                    }
                </Alert>
            </div>
        </div>
    )
};

export default HomeNoAuth;