import firebase from "firebase/compat";
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyApf1C_PgvLGElHHLt3MpFahZoj8qCGxDU",
    authDomain: "cs-ia-dev.firebaseapp.com",
    projectId: "cs-ia-dev",
    storageBucket: "cs-ia-dev.appspot.com",
    messagingSenderId: "1064345872549",
    appId: "1:1064345872549:web:7ad75206ffdd5acbce454c",
    measurementId: "G-8S3R895PJM"
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const db = firebase.firestore();
const googleProvider = new firebase.auth.GoogleAuthProvider();

const signInWithGoogle = async () => {
    try {
        const res = await auth.signInWithPopup(googleProvider);
        const user = res.user;
        const query = await db
            .collection("users")
            .where("uid", "==", user.uid)
            .get();
        if (query.docs.length === 0) {
            await db.collection("users").doc(user.uid).set({
                uid: user.uid,
                name: user.displayName,
                authProvider: "google",
                email: user.email,
                plants: [],
            });
        }
    } catch (err) {
        console.error(err);
        alert(err.message);
    }

    window.location.href='/greenhouse';
};

const logout = async () => {
    await auth.signOut();
    window.location.href='/';
};

const storage = firebase.storage();

export {
    auth,
    db,
    storage,
    signInWithGoogle,
    logout,
};