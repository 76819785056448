// Dependency Imports
import React, {useEffect} from "react";
import {useRecoilState} from "recoil";
import {plantState} from './atoms.js';

// Firebase Imports
import './firebase'; // Local firebase configuration & scripts file
import {auth, db} from "./firebase";
import {useAuthState} from "react-firebase-hooks/auth";

// Style imports
import './styles/App.css';

// Component Imports
import GreenhouseFrame from "./components/GreenhouseFrame";
import HomeNoAuth from "./pages/HomeNoAuth";

/*
* Notes
* -----
* Using recoil.js state management for react; help docs here -> https://recoiljs.org/docs/introduction/getting-started
* using firebase for auth and db; firebase.js file
* */


function App() {

    // State for authentication & recoil state
    const [user] = useAuthState(auth);
    const [userPlants, setUserPlants] = useRecoilState(plantState);

    // This sometimes takes a few tries to load the latest data, when implementing, add a check to ensure that the data is not empty
    // Helped from here -> https://firebase.google.com/docs/firestore/query-data/queries#simple_queries
    const getPlants = async () => {
        let plantRefs = [];
        const userRef = db.collection('users').where("uid", "==", user.uid);
        const snapshot = await userRef.get();
        snapshot.forEach(doc => {
            plantRefs = doc.data().plants;
        })

        // Avoids the case if user has no plants
        if (plantRefs[0] === null) {
            return null;
        }

        // Getting plant data based on user reference
        const plantData = []; // New array of json objects that we can store the plant data inside of

        // This finally works, needed to use await because tht getPlantData function is asynchronous
        for (let i = 0; i < plantRefs.length; i++) {
            const pref = db.collection('plants').doc(plantRefs[i]);
            const doc = await pref.get();
            plantData.push(doc.data());
        }

        setUserPlants(plantData);

        return null;
    };


    useEffect(() => {
        async function fetchData() {
            if(auth.currentUser !== null){
                console.log(userPlants);
                await getPlants();
            } else if (auth.currentUser === null){

            };
        }
        fetchData().then(r => null);
    }, [user]);


    return (
        <div className="App">
            <br/>
            <br/>
            <br/>
            {user ? <div>
                <h3 style={{align: 'center'}}><b>My Greenhouse</b></h3>
                <hr />
                <GreenhouseFrame/>
            </div> : <HomeNoAuth />}
        </div>
    );
}

export default App;
