import React, {useEffect, useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {useRecoilState} from "recoil";
import {plantState} from '../atoms.js';
import {useAuthState} from "react-firebase-hooks/auth";
import {auth, db, storage} from "../firebase";
import {Button, Form} from "react-bootstrap";
import {useForm} from 'react-hook-form';
import '../styles/RemovePlant.css';

const RemovePlant = () => {

    const [user] = useAuthState(auth);
    const [userPlants, setUserPlants] = useRecoilState(plantState);
    const [currentSelection, setCurrentSelection] = useState('');
    const [validation, setValidation] = useState(false);

    // Form State
    const {register, handleSubmit} = useForm();

    const getPlants = async () => {
        let plantRefs = [];
        const userRef = db.collection('users').where("uid", "==", user.uid);
        const snapshot = await userRef.get();
        snapshot.forEach(doc => {
            plantRefs = doc.data().plants;
        })

        // Avoids the case if user has no plants
        if (plantRefs[0] === null) {
            return null;
        }

        // Getting plant data based on user reference
        const plantData = []; // New array of json objects that we can store the plant data inside of

        // This finally works, needed to use await because tht getPlantData function is asynchronous
        for (let i = 0; i < plantRefs.length; i++) {
            const pref = db.collection('plants').doc(plantRefs[i]);
            const doc = await pref.get();
            plantData.push(doc.data());
        }

        setUserPlants(plantData);

        return null;
    };

    useEffect(() => {
        async function fetchData() {
            if (auth.currentUser !== null) {
                await getPlants();
            };
        }
        fetchData().then(r => null);
    }, [user]);

    const removePlant = async (plantID) => {

        // Now remove plant from the users inventory
        const userRef = db.collection('users').doc(user.uid);
        const doc = await userRef.get();

        // Getting current state of array, as firestore doesn't allow updated of single array entries
        let currArray = doc.data().plants;
        currArray.splice(currArray.indexOf(plantID), 1);

        await userRef.set({
            plants: currArray,
        }, {merge: true});

        // Remove image from firestore
        const plantDoc = await db.collection('plants').doc(plantID).get();
        const imageRef = storage.ref('images/' + plantDoc.data().imageName);
        await imageRef.delete();
        console.log('removed image w/ name: ' + plantDoc.data().imageName);


        // Removes the plant from the collection, does nothing if it doesn't exist
        await db.collection('plants').doc(plantID).delete();

        // load the new plants
        await getPlants();
        window.location.href = "/greenhouse"; // Redirects to the home-page after the data has been updated locally and in firebase
        return null;
    };

    const onSubmit = async (data) => {
        const id = data.idToRemove;
        await removePlant(id);
    };

    const onChange = (event) => {
        if (event.target.value.length > 5) {
            setCurrentSelection(event.target.value);
            console.log(currentSelection);
            return null;
        } else { return null }
    };

    const SelectMap = () => {
        return (
            <Form.Select onChange={onChange} {...register("idToRemove", {required: true})}>
                {userPlants.map((plant) => {
                    return (
                        <option key={plant.plantID} value={plant.plantID}>Plant Name: {plant.name}</option>
                    );
                })}
            </Form.Select>
        );
    };

    const PreviewScreen = () => {
        let v = [];
        userPlants.map((plant) => {
            if (plant.plantID === currentSelection) {
                v = [plant.name, plant.imageUrl, plant.description];
            } else {
                return null;
            }
        });

        if (v.length > 1) {
            return (
                <div className={'right'}>
                    <img className={'image'} src={v[1]} alt={'plant'} />
                    <br/>
                    <br/>
                    <h4>{v[0]}</h4>
                    <p><b>Description:</b> {v[2]}</p>
                </div>
            );
        } else {
            return null;
        }
    };

    // Return form content here
    return (
        <div id={"wrap"}>
            <div id={"left"}>
                <br/>
                <br/>
                <br/>
                {user ? <div style={{textAlign: "left"}}>
                    <Form onChange={onChange} onSubmit={handleSubmit(onSubmit)} style={{padding: '20px'}}>
                        <Form.Label>Plant to Remove <p style={{color: "red", display: 'inline-block'}}>*</p></Form.Label>
                        <SelectMap/>
                        <br/>
                        <Form.Check onChange={e => setValidation(e.target.checked)} label={'Are you sure you want to remove the plant'} />
                        <br/>
                        <Button variant={'outline-danger'} type={'submit'} disabled={!validation}>
                            Submit Plant for Removal
                        </Button>
                    </Form>
                </div> : <p>
                    Sorry, you cannot access this page if you are not logged in!
                </p>
                }
            </div>
            <div id={"right"}>
                <h3>Preview of plant to be removed</h3>
                <hr />
                {(currentSelection !== '') ?
                        <PreviewScreen/>
                    : <p></p>
                }
            </div>
        </div>
    );

};

export default RemovePlant;