import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from "./App";
import AddPlant from "./pages/AddPlant";
import RemovePlant from "./pages/RemovePlant";
import Edit from "./pages/Edit";
import Greenhouse from "./pages/Greenhouse";
import HomeNoAuth from "./pages/HomeNoAuth";
import {RecoilRoot} from "recoil";
import Navigation from "./components/Navigation";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'



ReactDOM.render(
    <React.StrictMode>
        {/*Need this so that I am able to use the global state across all the components*/}
        <RecoilRoot>
            <Router>
                <Navigation />
                <Routes>
                    <Route path={'/'} exact element={<HomeNoAuth />} />
                    <Route path={'/greenhouse'} exact element={<Greenhouse />} />
                    <Route path={'/addplant'} exact element={<AddPlant />} />
                    <Route path={'/removeplant'} exact element={<RemovePlant />} />
                    <Route path={'/edit'} exact element={<Edit />} />
                </Routes>
            </Router>
        </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root')
);

