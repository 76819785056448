// This component is responsible for rendering the plants that are stored in the users inventory
import React from "react";
import {useRecoilState} from "recoil";
import {plantState} from '../atoms';

// For watering time
import {ProgressBar, Button} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {Note} from "./Note";

// Style imports
import '../styles/GreenhouseFrame.css'
import WaterButton from "./WaterButton";

const GreenhouseFrame = () => {

    // Uses the state from the atoms file that is shared with the main App.js
    const [plants, setPlants] = useRecoilState(plantState);

    // This returns the watering time from seconds to whole days
    const wateringTime = (seconds) => {
        return Math.floor(seconds / (3600 * 24));
    };

    const progressDate = (wateringTimeSec, dateAdded) => {
        const plantDate = dateAdded.toDate();
        const date = new Date();
        const between = Math.abs(date - plantDate) / 1000;
        const val = between / wateringTimeSec; // Seconds
        return val;
    }

    const variantCalc = (val) => {
        if (val > 0.8) {
            return 'danger';
        } else if (val < 0.8 && val > 0.5) {
            return 'warning';
        } else if (val < 0.5) {
            return 'success';
        }
    }


    return plants.map((plant) => {
        return (
            <div className={"frameComp"} key={plant.name + Math.random()} style={{display: 'inline-block', padding: '20px'}}>
                <img className={"plantImage"} src={plant.imageUrl} alt={'plant'} width={300} height={310}/>
                <br />
                <br />
                <h3><u>{plant.name}</u></h3>
                <p>{plant.description}</p>
                <p><b>Watering time (days):</b> {wateringTime(plant.wateringTimeSec)}</p>
                <ProgressBar now={progressDate(plant.wateringTimeSec ,plant.dateAdded)} min={0} max={1} variant={variantCalc(progressDate(plant.wateringTimeSec, plant.dateAdded))} />
                <>{(progressDate(plant.wateringTimeSec, plant.dateAdded) > 0.8) ? <p style={{color: "red"}}> <b> Time to water.  </b></p> : <p style={{color: "#1181d5"}}> <b> No need to water yet! </b></p>}</>
                <div>
                    <WaterButton plantID={plant.plantID} plantName={plant.name} />
                </div>
                <br />
                <Note plantName={plant.name} plantNote={plant.note} plantID={plant.plantID} />
                <div style={{display: 'inline-block', paddingLeft: "10px"}} >
                    <Button onClick={(e) => window.location.href="/edit?plantID=" + plant.plantID} variant={"outline-warning"}>Edit the plant</Button>
                </div>
            </div>
        );
    });

};

export default GreenhouseFrame;