import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {auth, db} from "../firebase";
import {Button, Form} from "react-bootstrap";
import {useForm} from 'react-hook-form';
import firebase from "firebase/compat";
import "../styles/EditPlant.css";
import "bootstrap/dist/css/bootstrap.min.css";



const Edit = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const idToEdit = searchParams.get("plantID");
    const [busy, setBusy] = useState(true);
    const [plantData, setPlantData] = useState({});
    const {register, handleSubmit} = useForm();

    useEffect(async () => {
        console.log(busy);
        const doc = await db.collection("plants").doc(searchParams.get("plantID")).get();
        setPlantData(doc.data());
        console.log(plantData);
        setBusy(false);
        console.log(busy + plantData.name);
    }, []);

    const submitUpdate = async (dataFromForm) => {
        const wateringTime = Math.floor(dataFromForm.wateringTime * 86400);

        let newData = {
            name: dataFromForm.name,
            description: dataFromForm.description,
            note: dataFromForm.note,
            wateringTimeSec: wateringTime,
        };

        // this is so that the watering time does not reset if the user updates other information
        if (wateringTime === plantData.wateringTimeSec) {
            newData = {
                name: dataFromForm.name,
                description: dataFromForm.description,
                note: dataFromForm.note,
            };
        }

        const plantToUpdate = await db.collection('plants').doc(idToEdit);
        await plantToUpdate.set(newData, {merge: true});

        window.location.href="/greenhouse";
    };

    const Image = (props) => {
      return <img style={{"borderRadius":"20px"}} src={props.imageUrl} width={"400px"} alt={""} />
    };


    return (
        <div id={"wrap"}>
            <div id={"left"}>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                {busy ? <> <p>t</p> </> : <div>
                    <Form style={{textAlign: "left"}} onSubmit={handleSubmit(submitUpdate)}>
                        <Form.Label>Plant Name <p style={{color: "red", display: 'inline-block'}}>*</p></Form.Label>
                        <Form.Control defaultValue={plantData.name} type={'text'} {...register("name", {required: true})} />
                        <br/>
                        <Form.Label>Description <p style={{color: "red", display: 'inline-block'}}>*</p></Form.Label>
                        <Form.Control defaultValue={plantData.description} type={'text'} {...register("description", {required: true})} />
                        <br/>
                        <Form.Label>Additional Notes </Form.Label>
                        <Form.Control defaultValue={plantData.note} type={'text'} {...register("note", {required: false})} />
                        <br/>
                        <Form.Label>Watering time (days) <p style={{color: "red", display: 'inline-block'}}>*</p></Form.Label>
                        <Form.Control defaultValue={plantData.wateringTimeSec / 86400} type={'text'} {...register("wateringTime", {required: true})} />
                        <br/>
                        <Button disabled={false} variant={'primary'} type={'submit'}>
                            Submit Updated Plant Information
                        </Button>
                        <div style={{display: "inline-block", paddingLeft: "20px"}}>
                            <Button disabled={false} variant={'danger'} onClick={(e) => window.location.href="/greenhouse"}>
                                Cancel Changes and Return Home
                            </Button>
                        </div>
                    </Form>
                </div>}
            </div>
            <div id={"right"}>
                <br/>
                <br/>
                <br/>
                <br/>
                <h2 style={{"textAlign":"center"}}><b>Currently editing: </b> {plantData.name}</h2>
                <br/>
                <Image imageUrl={plantData.imageUrl} />
            </div>
        </div>
    );

};

export default Edit;