import { Navbar, NavDropdown, Button, Nav, Container } from "react-bootstrap";
import logo from "../logo.svg";

// Google icons for login buttons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGoogle} from "@fortawesome/free-brands-svg-icons/faGoogle";

// Firebase Imports
import '../firebase'; // Local firebase configuration & scripts file
import {auth, db, signInWithGoogle, logout} from "../firebase";
import {useAuthState} from "react-firebase-hooks/auth";

const onButtonClick = () => {
    window.open("https://github.com/71xn/futuristfest");
};

const Navigation = () => {

    const [user] = useAuthState(auth);

    return (
        <Navbar collapseOnSelect bg="dark" variant="dark" fixed="top" expand={"sm"}>
            <Container>
                <Navbar.Brand href="/">
                    <img
                        alt=""
                        src={logo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{" "}
                    Digital Greenhouse
                </Navbar.Brand>
                <Navbar.Toggle className={"navbar-expand-xl"} aria-controls={'responsive-navbar-nav'} />
                <Navbar.Collapse id={'responsive-navbar-nav'}>
                    <Nav className="me-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        {user ?
                            <Nav.Link href="/greenhouse">Greenhouse</Nav.Link> : <></>
                        }
                        {user ?
                            <NavDropdown title={"Plant Management"} id={"basic-nav-dropdown"} menuVariant={"dark"}>
                                    <Nav.Link href="/addplant">Add a Plant</Nav.Link>
                                    <Nav.Link href="/removeplant">Remove a Plant</Nav.Link>
                            </NavDropdown>
                        : <></>
                        }
                    </Nav>
                        {user ?
                            <Button variant="outline-warning" onClick={logout}>
                                <FontAwesomeIcon icon={faGoogle}/> Sign Out
                            </Button>
                            :
                            <Button variant="outline-success" onClick={signInWithGoogle}>
                                <FontAwesomeIcon icon={faGoogle}/> Sign In with Google
                            </Button>
                        }
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navigation;