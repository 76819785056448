import React, {useState} from "react";
import {Modal, Button} from "react-bootstrap";

// state management
import {plantState} from "../atoms";
import {useRecoilState} from "recoil";

export const Note = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleOpen = () => setShow(true);

    return (
      <>
          <Button variant={"outline-success"} onClick={handleOpen} >
              View Notes
          </Button>

          <Modal show={show} onHide={handleClose}aria-labelledby="contained-modal-title-vcenter"
                 centered >
              <Modal.Header>
                  <Modal.Title>📒 Notes for: {props.plantName}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{props.plantNote}</Modal.Body>
              <Modal.Footer>
                  <Button variant="outline-dark" onClick={(e) => {window.location.href='/edit?plantID=' + props.plantID}}>
                      ✏ Edit Notes
                  </Button>
                  <Button variant="outline-danger" onClick={handleClose}>
                      ❌ Close
                  </Button>
              </Modal.Footer>
          </Modal>

      </>
    );

}