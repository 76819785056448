import React, {useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth, db} from "../firebase";
import {Button, Modal} from "react-bootstrap";
import firebase from "firebase/compat";

const WaterButton = (props) => {

    // noinspection JSCheckFunctionSignatures
    const [user] = useAuthState(auth);

    // For the Modal Info Box
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleOpen = () => setShow(true);

    const water = async () => {

        handleClose(); // close the modal before proceeding

        if (!user) return null; // this should never be true, but just in case

        const plantDoc = await db.collection('plants').doc(props.plantID); // seems to add a new document

        await plantDoc.set({
            dateAdded: firebase.firestore.Timestamp.fromDate(new Date())
        }, {merge: true});

        window.location.href="/greenhouse";

    };

    return (
        <>
            <Button variant={"outline-info"} onClick={handleOpen}> 🌊 Water plant</Button>

            <Modal show={show} onHide={handleClose}aria-labelledby="contained-modal-title-vcenter"
                   centered >
                <Modal.Header>
                    <Modal.Title>🌊 Watering Confirmation: {props.plantName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure that you want to water this plant?</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-info" onClick={(e) => water()}>
                        🚿 Water Plant
                    </Button>
                    <Button variant="outline-danger" onClick={handleClose}>
                        ❌ Cancel and Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default WaterButton;