import React, {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {plantState} from '../atoms.js';
import {useAuthState} from "react-firebase-hooks/auth";
import {auth, db} from "../firebase";
import {useForm} from 'react-hook-form';
import {Form, Button, ProgressBar} from 'react-bootstrap';
import "../styles/AddPlant.css";
import "bootstrap/dist/css/bootstrap.min.css";


// Need firebase storage to upload and get image url
import {storage} from "../firebase";
import firebase from "firebase/compat";

const AddPlant = () => {

    // User State
    const [userPlants, setUserPlants] = useRecoilState(plantState);
    const [user] = useAuthState(auth);

    // Form State
    const {register, handleSubmit} = useForm();
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [upload, setUpload] = useState(false);

    const updateFirebase = async (data) => {

        console.log("adding plant w/ image: " + data.imageUrl);

        // Adding to plant collection
        const res = await db.collection('plants').add(data);
        // res.id -> ID of plant document that was added to firebase

        const plantDoc = await db.collection('plants').doc(res.id);
        await plantDoc.set({
            plantID: res.id,
        }, {merge: true});

        console.log('added document to plants collection w/ id: ' + res.id);

        // Now need to add plant id (res.id) to the logged-in users plants array

        // Get the users document
        const userRef = db.collection('users').doc(user.uid);
        const doc = await userRef.get();

        // Getting current state of plants array, as firestore doesn't allow updated of single array entries
        const currArray = doc.data().plants;

        const updatedArray = currArray.concat(res.id); // Update the array by concatenating the id of the new plant
        await userRef.set({
            plants: updatedArray,
        }, {merge: true});

        // Update the state of the local user plants array in recoil
        setUserPlants(updatedArray);

        window.location.href="/greenhouse";

        // Reloads to homepage if both tasks are done
    };

    // Form Stuff
    const onSubmit = async (formData) => {

        // Upload Image and get URL
        let imageURL = "";
        const fileName = user.uid + Math.random() + file.name;
        const uploadTask = storage.ref("/images/" + fileName).put(file);
        uploadTask.on("state_changed", snapshot => {
            },
            console.error,
            () => {
                storage
                    .ref("images")
                    .child(fileName)
                    .getDownloadURL()
                    .then((url) => {
                        imageURL = url;
                        console.log(imageURL);

                        const wateringTime = Math.floor(formData.wateringTime * 86400); // Needed to multiply rather than divide, whoops
                        const data = {
                            name: formData.name,
                            description: formData.description,
                            note: formData.note,
                            imageUrl: imageURL,
                            watered: false,
                            wateringTimeSec: wateringTime,
                            imageName : fileName,
                            dateAdded: firebase.firestore.Timestamp.fromDate(new Date()),
                        }

                        updateFirebase(data);

                    });
        });

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, function(snapshot) {
            let percent = snapshot.bytesTransferred / snapshot.totalBytes * 100;
            setProgress(percent);
            console.log(percent);
        });

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
            'complete': function() {
                console.log("uploaded image");
                setUpload(true);
            }
        });
    };

    const onFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    return (
        <div id={"container"}>
            {user ?
                <Form onSubmit={handleSubmit(onSubmit)} style={{padding: '20px'}}>
                    <br/>
                    <br/>
                    <br/>
                    <Form.Label>Plant Name <p style={{color: "red", display: 'inline-block'}}>*</p></Form.Label>
                    <Form.Control type={'text'} {...register("name", {required: true})} />
                    <br/>
                    <Form.Label>Description <p style={{color: "red", display: 'inline-block'}}>*</p> </Form.Label>
                    <Form.Control type={'text'} {...register("description", {required: true})} />
                    <br/>
                    <Form.Label>Additional Notes</Form.Label>
                    <Form.Control type={'text'} {...register("note", {required: false})} />
                    <br/>
                    <Form.Label>Image (png, jpg or jpeg {"<"} 20mb) <p style={{color: "red", display: 'inline-block'}}>*</p></Form.Label>
                    <Form.Control type={'file'} onChange={onFileChange}/>
                    <br/>
                    <Form.Label>Watering time (days) <p style={{color: "red", display: 'inline-block'}}>*</p></Form.Label>
                    <Form.Control type={'text'} {...register("wateringTime", {required: true})} />
                    <br/>
                    <Button style={{"textAlign":"center"}} disabled={!file} variant={'primary'} type={'submit'}>
                        Submit Plant
                    </Button>
                    <div style={{display: "inline-block", paddingLeft: "20px"}} >
                        <Button style={{"textAlign":"center"}} disabled={false} variant={'danger'} onClick={(e) => window.location.href="/greenhouse"}>
                            Cancel Changes
                        </Button>
                    </div>
                    <br />
                    <br />
                    <ProgressBar variant={"success"} animated now={progress} label={"Loading . . ."} />
                </Form>
                : <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <p>Sorry, you are not logged in.</p>
                </div>}
        </div>
    );
}

export default AddPlant;